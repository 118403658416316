import React from "react";
import { CicdTour } from "../../components/CicdTour";
import { PageLayout } from "../../components/PageLayout";

const GuidedTourPage: React.FC = () => {
  const heroOverlayLeadElement = <>A Guided Tour</>;
  const heroOverlaySecondaryElement = <>of our CI/CD pipeline</>;
  return (
    <PageLayout
      heroOverlayLeadElement={heroOverlayLeadElement}
      heroOverlaySecondaryElement={heroOverlaySecondaryElement}
      // heroBackgroundImage="url(https://images.mktw.net/im-291272?width=2000&height=1100)"
    >
      <CicdTour />
    </PageLayout>
  );
};

export default GuidedTourPage;
