import React from "react";
import { PageHeader } from "./PageHeader";
import { Paragraph } from "./Paragraph";
import { Section } from "./Section";
import { Heading2 } from "./Heading2";

export const CicdTour: React.FC = () => {
  return (
    <>
      <PageHeader primary="A Guided Tour" secondary="Of our CI/CD Pipeline" />
      <Section>
        <Heading2>Overview of Our CI/CD Pipeline</Heading2>
        <Paragraph>
          This section is a quick overview of the CI/CD Pipeline configuration
          that we deliver as part of our solution. This confuguration
          information tells the Azure hosting system how to build, test, and
          deploy your software. You can customize this pipeline or use it as a
          guide to build you own.
        </Paragraph>
        <Heading2>Start With the Source</Heading2>
        <Paragraph>
          Our solution is roughly composed of two types of source:
          <ul>
            <li>the client composed of Typescript and React components</li>
            <li>the backend composed of webAPIs built from .net Core and C#</li>
          </ul>
          We build each of these with different tools, but using the same
          pipeline configuration tool in Azure.
        </Paragraph>
        <Paragraph>
          - starts with VC tool Git only - 4 repos: procs, programs, backend,
          frontend - lots dependencies on Twoak (nuget) - BE depends on procs
          and procedures (nuget) - FE depends on TWOAK pack, Customer pack (NPM)
          build .net build + npm build (webpack)
        </Paragraph>
        <Heading2>Pipeline Steps</Heading2>
        <Paragraph>
          -- trigger conditions met -- stages: ---environment validation,
          ---version calculation ---package restores --- build --- test --- push
          to package manager --- record build in VC --- announce new build ---
          Deploy to host (FE and BE)
        </Paragraph>
      </Section>
    </>
  );
};
